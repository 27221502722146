import { CalendarManager } from '../../features/calendar/calendar.manager';
import { useCallback, useEffect, useState } from 'react';
import { DateViewModel } from '../../features/calendar/calendar.model';
import { apiClient } from '../../App';
import { CalendarEventResponse, TourKind } from '../../api/response-model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../store';
import { CalendarAction } from '../../slice/calendar-slice';


const getIndexTourKind = (response: CalendarEventResponse | null, responseTourKindId?: string) => {
  const idx = response?.tour_kind.findIndex(v => String(v.id) === String(responseTourKindId));
  return idx ?? 0;
}

export const TourKindTab = () => {
  const { response, oneDayTourKindList, selectedSearchOptionState } = useSelector((state: RootState) => ({
    response: state.calendar.response,
    oneDayTourKindList: state.calendar.oneDayTourKindList,
    selectedSearchOptionState: state.calendar.selectedSearchOptionState,
  }));
  const dispatch = useDispatch();

  const [tabList, setTabList] = useState<TourKind[]>([]);
  // const [currentTourKind, setCurrentTourKind] = useState<TourKind | null>(null);

  const handlerSetTabList = useCallback((tourKindList: TourKind[]) => {
    setTabList(tourKindList);
  }, []);

  // const handlerSetCurrentTourKind = useCallback((tourKind: TourKind) => {
  //   setCurrentTourKind(tourKind);
  // }, []);
  const getisOneDay = useCallback(() => {

  }, []);

  const onChangeTab = useCallback((tourKind: TourKind | 'combo') => {
    const ssos = selectedSearchOptionState;
    if (tourKind === 'combo') {
      if (oneDayTourKindList[0]) {
        dispatch(CalendarAction.setSelectedSearchOptionState({...ssos, tour_kinds: [String(oneDayTourKindList[0].id)]}));
        apiClient.getTourScheduleList({...response.request, tour_kinds: [Number(oneDayTourKindList[0].id)]});
      }
    } else {
      dispatch(CalendarAction.setSelectedSearchOptionState({...ssos, tour_kinds: [String(tourKind.id)]}));
      apiClient.getTourScheduleList({...response.request, tour_kinds: [String(tourKind.id)]});
    }
  }, [response, selectedSearchOptionState, oneDayTourKindList]);

  useEffect(() => {
    // タブのツアーリスト更新
    const tabList = response?.tour_kind.filter(v => !v.one_day) ?? [];
    handlerSetTabList(tabList);
    // // 選択中のツアー更新
    // const tourKind = response.tour_kind[getIndexTourKind(response, String(response?.request.tour_kinds))];
    // handlerSetCurrentTourKind(tourKind);
    // dispatch(CalendarAction.setSelectedSearchOptionState({
    //   tour_place: String(response.request.tour_place || response.tour_places[0].id),
    //   tour_kinds: [String(response.request.tour_kinds ? response.request.tour_kinds[0] : 0)],
    // }));
  }, [response])

  useEffect(() => {

  }, [selectedSearchOptionState])


  return (
    <div className='tab_wrap'>
      <div className='tab'>
        { tabList.map((v ,i) => (
          <button
            key={`tab_${i}`}
            onClick={() => onChangeTab(v)}
            className={String(v.id) === String(selectedSearchOptionState.tour_kinds) ? 'selected' : ''}
          >
            {v.title}
          </button>
        ))}

        { oneDayTourKindList.length ? (
          <button
            key={`tab_${tabList.length}`}
            onClick={() => onChangeTab('combo')}
            className={oneDayTourKindList.map(v => v.id).includes(String(selectedSearchOptionState.tour_kinds[0])) ? 'selected' : ''}
          >
            {'コンボ'}
          </button>
        ) : (<></>)}
      </div>
    </div>
  );
};