import { CalendarAction } from '../slice/calendar-slice';
import { store } from '../store';
import { sleep } from '../utilities/sleep'

let apiRunCnt = 0;

export const apiDelay = (callback: (Promise<any>)[]): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    apiRunCnt++;
    // console.log('--- RUN!!---apiRunCnt = ', apiRunCnt)
    store.dispatch(CalendarAction.setIsLoading(true));
    await Promise.all([...callback, sleep(2000)]);
    if (--apiRunCnt <= 0) {
      store.dispatch(CalendarAction.setIsLoading(false));
    }
    // console.log('---END!!---apiRunCnt = ', apiRunCnt)

    resolve();
  })
}
// await apiDelay([
//   (async () => {
       
//   })(),
// ]);