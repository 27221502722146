import { configureStore } from "@reduxjs/toolkit";
import { CalendarReducer } from "./slice/calendar-slice";


export const store = configureStore({
  reducer: {
    calendar: CalendarReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
