import { URLSearchParams } from "node:url";

/**
 * オブジェクトのkeyとvalueを取得し、クエリパラメタ（String）に変換して返す
 * @param object 
 * @returns 
 */
export const object2QueryParamStr = <T>(object: T) => {
  const keyList = Object.keys(object);
  const valueList = Object.values(object);
  let queryParamStr = '';

  keyList.forEach((key, i) => {
    queryParamStr += i !== 0 ? '&' : '';

    if (Array.isArray(valueList[i])) {
      const arr = valueList[i] as string[];
      arr.forEach((v, vi) => {
        queryParamStr += vi !== 0 ? '&' : '';
        queryParamStr += `${key}[${vi}]=${v}`; 
      })
    } else {
      queryParamStr += `${key}=${valueList[i]}`;
    }
  })
  return queryParamStr;
}
