import { CalendarManager } from '../../features/calendar/calendar.manager';
import { useCallback, useEffect, useState } from 'react';
import { DateViewModel } from '../../features/calendar/calendar.model';
import { apiClient, } from '../../App';
import { TourPlaces } from '../../api/response-model';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarAction } from '../../slice/calendar-slice';


/**
 * クライアント側｜ツアー拠点選択ラジオボタン
 * @param param 
 * @returns 
 */
export const TourPlaceRadio = () => {
  const { response } = useSelector((state: RootState) => ({
    response: state.calendar.response,
  }));
  const dispatch = useDispatch();
  const getIndexTourPlace = useCallback((responseTourPlaceId?: string) => {
    const idx = response.tour_places.findIndex(v => String(v.id) === String(responseTourPlaceId));
    return idx === -1 ? 0 : idx;
  }, [response]);

  const [currentPlace, setCurrentPlace] = useState(
    response.tour_places[getIndexTourPlace(String(response.request.tour_place))]
  );

  const onChangeRadio = useCallback((obj: TourPlaces) => {
    setCurrentPlace(obj);
    // ツアー情報を取得するために一度、APIを走らせる
    apiClient.getTourScheduleList({...response.request, tour_place: String(obj.id)}).then((res) => {
      apiClient.getTourScheduleList({...response.request, tour_place: String(obj.id), tour_kinds: [String(res.tour_kind[0].id)]})
      // コンボプルダウン内のメニュー（ツアーリスト）更新
      const oneDayList = res.tour_kind.filter(v => v.one_day) ?? [];
      dispatch(CalendarAction.setOneDayTourKindList(oneDayList));
      // 選択中のステータス更新
      dispatch(CalendarAction.setSelectedSearchOptionState({
        tour_place: String(obj.id),
        tour_kinds: [String(res.tour_kind[0].id)],
      }));
    })
  }, [response]);

  useEffect(() => {
  }, [response])

  return (
    <div className='radio tour_place'>
      { response.tour_places.map((v, i) => (
        String(v.id) !== '3' ? (
          <label
            className={String(v.id) === String(response.request.tour_place) ? 'checked' : ''}
            key={`label_${i}`}
          >
          <input
            key={`radio_${i}`}
            type={'radio'}
            checked={String(v.id) === String(response.request.tour_place)}
            onChange={() => onChangeRadio(v)}
          />{v.title}{String(v.id) === '1' ? `／${response.tour_places[2].title}` : ''}
          </label>
        ) : (<></>)
      ))}
    </div>
  );
};