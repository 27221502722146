import React, { useCallback, useEffect } from 'react';
import logo from './logo.svg';
import './App.scss';
import { ApiClient } from './api/api-client';
import './App.scss';
import { Calendar } from './components/calendar/Calendar';
import { CalendarEventResponse } from './api/response-model';
import { CalendarAction } from './slice/calendar-slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store';
import { ApiAdmin } from './api/api-admin';
import { getMonthEventListUtility } from './utilities/calendar-utillitis';
import { Loading } from './components/loading/loading';
import { apiDelay } from './api/api-delay-response';
import { queryParamStr2Object } from './utilities/query-param-2-object';
import { CalendarEventRequest } from './api/request-model';

// export const host = 'https://winds.loopback.marietta.dev/';
// export const host = 'https://winds.marietta.dev';
export const host = 'https://winds-rafting.jp';
/** trueを指定するとローディング画面がずっと表示される */
export const isTestLoading = false;
/** 確認する画面を指定する */
export const isTestSiteKind: string = 'null'; // 'admin' or 'client' or 'null'
/** ApiConsoleを表示するかどうか */
export const isApiConsole: boolean = true;
/** Admin側のスケジュール取得APIの代わりに、クライアント側のスケジュール取得APIを使用するかどうか */
export const isUseClientApi: string = 'true';
export const apiClient = new ApiClient({ debug: false });
export const apiAdmin = new ApiAdmin({ debug: false, isLoadLocalJson: isTestSiteKind === 'admin' });
const initialApiParam = {
  tour_place: 1,
  tour_kinds: [1],
  event_year: new Date().getFullYear(),
  event_month: new Date().getMonth() + 1,
}

type TourPlaceStr = 'rafting' | 'family' | 'showerclimbing' | 'ducky' | 'sup' | 'hydrospeed' | 'combo';
type TourPlaceTitle = 'ラフティング' | 'ファミリーラフティング' | 'シャワークライミング' | 'ダッキー' | 'SUP' | 'ハイドロスピード' | 'コンボ';

const App = () => {
  const { siteKind, pageState, isLoading } = useSelector((state: RootState) => ({
    siteKind: state.calendar.response.site_kind,
    pageState: state.calendar.pageState,
    isLoading: state.calendar.isLoading,
  }));
  const dispatch = useDispatch();

  const getPageTypeTourPlace = useCallback(() => {
    const arr = window.location.href.split('/');
    const arr2 = arr.filter(v => v != '');
    return (arr2.pop() || 'rafting') as TourPlaceStr;
  }, []);

  const getTourPlaceTitle = useCallback((): TourPlaceTitle => {
    const tourPlaceStr = getPageTypeTourPlace();
    switch (tourPlaceStr) {
      case 'rafting':
        return 'ラフティング';
      case 'family':
        return 'ファミリーラフティング';
      case 'showerclimbing':
        return 'シャワークライミング';
      case 'ducky':
        return 'ダッキー';
      case 'sup':
        return 'SUP';
      case 'hydrospeed':
        return 'ハイドロスピード';
      case 'combo':
        return 'コンボ';
      default:
        return 'ラフティング'
    }

  }, [getPageTypeTourPlace]);

  const getDefaultSelected = useCallback((initResponse: CalendarEventResponse): Promise<{
    res: CalendarEventResponse,
    tourPlaceId: string,
    tourKindId: string,
  }> => {
    return new Promise(async (resolve, reject) => {
      const today = {
        event_year: new Date().getFullYear(),
        event_month: new Date().getMonth() + 1,
      };
      const { tour_places, tour_kind } = initResponse;
      const currentPageType = getTourPlaceTitle();

      for (let i = 0; i < tour_places.length; i++) {
        const res = await apiClient.getTourScheduleList({
          tour_place: tour_places[i].id,
          tour_kinds: [String(tour_kind[i].id)],
          ...today,
        });
        
        // コンボ以外のツアーを配列に格納
        const id = res.tour_kind.find((v) => v.title === currentPageType)?.id;
        const oneDayId = res.tour_kind.find((v) => v.one_day)?.id;
        // 
        if (id !== undefined || oneDayId !== undefined) {
          resolve({
            res: res,
            tourPlaceId: String(tour_places[i].id),
            tourKindId: String(id ?? oneDayId), 
          });
          break;
        }
      }
      resolve({
        res: initResponse,
        tourPlaceId: tour_places[0].id,
        tourKindId: String(tour_kind[0].id),
      });
    });
  }, []);

  /** クライアントサイト側 - 初期データ取得するためのAPIを実行 */
  const getInitialDataClient = useCallback(async () => {
    // ツアー情報を取得するために一度、APIを走らせる
    const res = await apiClient.getTourScheduleList(initialApiParam, {isShowLoading: false});
    const defaultSelected = await getDefaultSelected(res);
    apiClient.getTourScheduleList({
      tour_place: defaultSelected.tourPlaceId,
      tour_kinds: [defaultSelected.tourKindId],
      event_year: new Date().getFullYear(),
      event_month: new Date().getMonth() + 1,
    },
    {isShowLoading: false}
    )
    // コンボプルダウン内のメニュー（ツアーリスト）更新
    const oneDayList = defaultSelected.res.tour_kind.filter(v => v.one_day) ?? [];
    dispatch(CalendarAction.setOneDayTourKindList(oneDayList));
    // 選択中のステータス更新
    dispatch(CalendarAction.setSelectedSearchOptionState({
      tour_place: defaultSelected.tourPlaceId,
      tour_kinds: [defaultSelected.tourKindId],
    }));
    if (!isTestLoading) {
      dispatch(CalendarAction.setPageState('calendar'));
    }
  }, []);

  /** 管理サイト側 - 初期データ取得するためのAPIを実行 */
  const getInitialDataAdmin = useCallback(async () => {
    await apiDelay([
      (async () => {
        const today = {
          y: new Date().getFullYear(),
          m: new Date().getMonth() + 1,
        }
        const param = createInitParam(initialApiParam);
        // ツアー情報を取得するために一度、APIを走らせる
        const res = await apiAdmin.getTourScheduleList(param.data);
        const res2 = await apiAdmin.getTourScheduleList({
          tour_place: param.isQueryParam.tour_place ? param.data.tour_place : res.tour_places[0].id,
          tour_kinds: res.tour_kind.map(v => String(v.id)),
          event_year: param.isQueryParam.event_year ? param.data.event_year : today.y,
          event_month: param.isQueryParam.event_month ? param.data.event_month : today.m,
        }
      )
        const list = getMonthEventListUtility(res2, today.y, today.m);
        dispatch(CalendarAction.setTourEventAllList(list));
    
        dispatch(CalendarAction.setResponse(res2));
        // コンボプルダウン内のメニュー（ツアーリスト）更新
        const oneDayList = res.tour_kind.filter(v => v.one_day) ?? [];
        dispatch(CalendarAction.setOneDayTourKindList(oneDayList));
        // 選択中のステータス更新
        dispatch(CalendarAction.setSelectedSearchOptionState({
          tour_place: param.isQueryParam.tour_place ? param.data.tour_place : res.tour_places[0].id,
          tour_kinds: param.isQueryParam.tour_kinds ? res2.tour_kind.map(v => String(v.id)) : res.tour_kind.map(v => String(v.id)),
        }))
      })(),
    ]);
    if (!isTestLoading) {
      dispatch(CalendarAction.setPageState('calendar'));
    }
  }, []);

  const createInitParam = useCallback((param: CalendarEventRequest) => {
    const {
      tour_place, tour_kinds, event_year, event_month,
    } = param;
    const obj = queryParamStr2Object(window.location.search) as any;
    const isQueryParam = {
      tour_place: obj["tour_places[0]"] !== undefined,
      tour_kinds: false,
      event_year: obj["event_year"] !== undefined,
      event_month: obj["event_month"] !== undefined,
    }

    return {
      data: {
        tour_place: isQueryParam.tour_place ? obj["tour_places[0]"] : tour_place,
        tour_kinds: tour_kinds,
        event_year: Number(isQueryParam.event_year ? obj["event_year"] : event_year),
        event_month: Number(isQueryParam.event_month ? obj["event_month"] : event_month),
      },
      isQueryParam: isQueryParam,
    }
  }, [queryParamStr2Object]);

  useEffect(() => {
    try {
      const element = document.getElementById('calendar-data');
      const innerText = element?.innerText || '';
      const response = JSON.parse(innerText) as CalendarEventResponse;
      response.site_kind === 'client'? getInitialDataClient() : getInitialDataAdmin();
    } catch {
      if (isTestSiteKind === 'client' || isTestSiteKind === 'null' ) {
        getInitialDataClient(); 
      } else if (isTestSiteKind === 'admin') {
        getInitialDataAdmin();
      }
    }
  }, []);

  return (
    <div className="App">
      {/* {siteKind} */}
      { pageState === 'initialLoading' ? <Loading /> : <></> }
      { pageState === 'calendar' ? <Calendar /> : (<></>) }
    </div>
  );
};

export default App;
