import telIcon from '../../assets/phone.svg';

export const TelButton = () => {

  return (
    <>
      <div className='img_wrap'>
        <a
          href="tel:0428859210"
          data-toggle="phoneTooltip"
          data-original-title="0428-85-9210 までお問い合わせ下さい	"
        >
          <img src={telIcon} width={50} height={50} />
        </a>
      </div>
    </>
  )
}