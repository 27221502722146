import { host, isApiConsole } from "../App";
import { CalendarAction } from "../slice/calendar-slice";
import { store } from "../store";
import { LogDecorator } from "../utilities/log-decorator";
import { object2QueryParamStr } from "../utilities/object-2-query-param";
import { toCalendarEventApiRequest, toCalendarEventApiResponse, toCalendarEventResponse } from "./replace";
import { CalendarEventRequest,   } from "./request-model";
import { CalendarEventResponse, CalendarEventApiResponse, Data } from "./response-model";

type Param = {
  debug?: boolean,
}
export class ApiClient {
  private debug = false;

  constructor(param: Param) {
    const { debug } = param;
    this.debug = Boolean(debug);
  }

  get url() {
    return window.location.href;
  }


  getTourScheduleList(queryParam: CalendarEventRequest, option?: {isShowLoading?: boolean}): Promise<CalendarEventResponse> {
    return new Promise((resolve, reject) => {
      if (option?.isShowLoading === undefined || option?.isShowLoading) {
        store.dispatch(CalendarAction.setIsLoading(true));
      }
      const replaceTourPlace = toCalendarEventApiRequest(queryParam, 'client');
      const str = object2QueryParamStr(replaceTourPlace);
      const queryParamStr = `${str ? '?' : ''}${str}`
      // const replaceQueryParamStr = queryParamStr.replace('tour_kinds', 'tour_kind'); // 開発途中でtour_kinds　→　tour_kindに変更になったため文字列置換
      const replaceQueryParamStr = queryParamStr;

      const url = `${host}/tour/calendar/${replaceQueryParamStr}`;
      window.fetch(url)
      .then((res) => res.text())
      .then((text) => {
        const doc = new DOMParser().parseFromString(text, 'text/html');
        const element = doc.getElementById('calendar-data');
        const innerText = element?.innerText || '';
        try {
          const response = JSON.parse(innerText) as CalendarEventApiResponse;
          if (isApiConsole) {
            window.console.groupCollapsed(...LogDecorator(`[<green>200</green>] - ${replaceQueryParamStr}`));
            window.console.log('Request', replaceTourPlace);
            window.console.log('Response', response);
            window.console.groupEnd();
          }
          const replaceRes = toCalendarEventResponse(response, 'client');
          store.dispatch(CalendarAction.setResponse(replaceRes));
          resolve(replaceRes);
        } catch (err) {
          if (isApiConsole) {
            window.console.error(err);
            window.console.warn('仮データをセットしました。')
            window.console.groupCollapsed(...LogDecorator(`[<red>error</red>] - ${replaceQueryParamStr}`));
            window.console.log('Request', replaceTourPlace);
            window.console.log('Response', 'error');
            window.console.groupEnd();
          }
        }
      });    
    })
  }

  changePageReserveEdit(queryParam: Data) {
    const {
      tour_kind,
      event_year, event_month, event_day, start_time, end_time,
      tour_place,
      price_adult, price_child,
      preferred_course,
      id,
    } = queryParam;
    // const tourKind = `tour_kind=${tour_kind}`;
    // const preferredDate = `preferred_date=${event_year}/${event_month}/${event_day}`;
    // const startTime = `start_time=${start_time.slice(0, -3)}`;
    // // const endTime = `end_time=${end_time.slice(0, -3)}`;
    // const tourPlace = `tour_place=${tour_place}`;
    // const adultPrice = `unit_price1=${price_adult}`;
    // const childPrice = `unit_price2=${price_child}`;
    // const preferredCourse = `preferred_course=${preferred_course}`;
    const _id = `id=${id}`;

    const url = `${host}/reservation/`;
    // window.location.href = `${url}?${tourKind}&${preferredDate}&${startTime}&${tourPlace}&${adultPrice}&${childPrice}&${preferredCourse}`;
    window.location.href = `${url}?${_id}`;

  }
}
